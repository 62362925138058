<template>
  <div>
    <div
      style="
        background-color: #EEEEEE;
      "
    >
      <div class="containere">

        <section>
          <article class="welcome-article">
            <div class="container">
              <div class="row pt-5 pb-5">
                <div class="col-md-1"></div>
                <div class="home_welcome_message col-md-10 text-center">
                  <h2 class="segoe">WELCOME TO</h2>
                  <p class="heading-two segoe">ACAKI LODGE</p>
                  <p class="paragraph">
                    The lodge derives its name from the Acholi word for “beginning”, “Genesis”, “Alpha”.
                    Acaki Lodge is strategically positioned at the gatehead of Kitgum Municipality.It is a halfway house for travellers heading to 
                    Kidepo Valley National Park punctuating the erstwhile long drive from Kampala. Set out on an expansive 20 acre leafy plain on 
                    the banks of Alango Stream, Acaki Lodge was constructed on what was formerly a garbage dump.It is a conservation miracle as 
                    the land has now been turned into a green park, a plus for eco-tourism.
                  </p>
                </div>
                <div class="col-md-1"></div>
              </div>
            </div>
          </article>
        </section>

        

        <section>
            <article>
              <div class="the-lodge-grid-section">
                <div>
                  <div class="text-center">
                    <h2 class="segoe">Immerse yourself in unparalleled natural beauty at Acaki Lodge's serene retreat.</h2>
                    <p class="heading-two segoe">A DELUXE HOSPITALITY</p>
                    <p class="content-paragraph segoe" style="color:#666666">
                      Acaki Lodge (Uganda), the apex of deluxe hospitality in Kitgum offers international comfort and standards combined with 
                      local charm. Acaki Lodge is a mid range lodge with 10 chalets built of local materials and thatching, 2 tents, 
                      a conference facility and gardens(for weddings and functions). With luxurious rooms opening onto the garden, 
                      this is a great option for guests looking for an intimate stay in a tranquil setting. 
                      It is a cool resting point strategically located between Kidepo and Murchison Falls National Parks
                    </p>
                    <button @click="$router.push({ path: '/accommodation' })" class="btn btn-lg content-btn mt-5 segoe">EXPLORE OUR COTTAGES</button>

                  </div>
                </div>
                <div class="bg-image">
                  <img src="../../../assets/images/acaki18.jpg" />
                </div>
                <div class="bg-image">
                  <img src="https://images.pexels.com/photos/59873/elephant-elephants-tanzania-safari-59873.jpeg" />
                </div>
                <div>
                  <div class="text-center">
                    <h2 class="segoe">Adventure Awaits You</h2>
                    <p class="heading-two segoe">EXPERIENCE ACAKI LODGE'S ENCHANTMENT</p>
                    <p class="content-paragraph segoe">
                      Discover the magic of Acaki Lodge, where nature’s splendor meets unparalleled comfort. Nestled in the heart of pristine wilderness, our lodge offers a perfect escape from the everyday. Relax in our luxurious cottages, each designed to harmonize with the natural surroundings and provide breathtaking views. Enjoy exceptional service and immerse yourself in a tranquil setting that promises both adventure and serenity. Acaki Lodge is your gateway to an unforgettable retreat, blending luxury with the essence of nature. Experience the ultimate relaxation and connection with the great outdoors.
                    </p>
                    <button class="btn btn-lg content-btn mt-5 segoe">DISCOVER PARADISE</button>
                  </div>
                </div>
              </div>
            </article>
        </section>



        <!-- <div class="welcome_grid text-center">
          <div class="welcome_flex">
            <div class="welcome_image">
              <img src="../../assets/images/room5.jpg" />
            </div>
            <div class="welcome_description">
              <div>
                <span class="heading">ACCOMMODATION</span><br />
                <small class="wel_text"
                  >Stylish, elegant and contemporary accommodation.</small
                >
              </div>
            </div>
            <div>
              <button
                class="btn btn-brown"
                @click="$router.push({ path: '/accommodation' })"
              >
                Explore More <span class="el-icon-d-arrow-right"></span>
              </button>
            </div>
          </div>
          <div class="welcome_flex">
            <div class="welcome_image">
              <img src="../../assets/images/gallery/locuc-hotel-gio-and-moh-87.jpg" />
            </div>
            <div class="welcome_description">
              <div>
                <span class="heading">DINING EXPERIENCE</span><br />
                <small class="wel_text"
                  >Cassowary Restaurant, “a foodie’s heaven”.</small
                >
              </div>
            </div>
            <div>
              <button
                class="btn btn-brown"
                @click="$router.push({ path: '/dining' })"
              >
                Explore More <span class="el-icon-d-arrow-right"></span>
              </button>
            </div>
          </div>
          <div class="welcome_flex">
            <div class="welcome_image">
              <img src="../../assets/images/pool2.jpg" />
            </div>
            <div class="welcome_description">
              <div>
                <span class="heading">LEISURE & FITNESS</span><br />
                <small class="wel_text"
                  >Pool, Massage & Gym. Rediscover inner balance.</small
                >
              </div>
            </div>
            <div>
              <button
                class="btn btn-brown"
                @click="$router.push({ path: '/leisure-and-fitness' })"
              >
                Explore More <span class="el-icon-d-arrow-right"></span>
              </button>
            </div>
          </div>
          <div class="welcome_flex">
            <div class="welcome_image">
              <img src="../../assets/images/gallah4.jpg" />
            </div>
            <div class="welcome_description">
              <div>
                <span class="heading">EVENTS</span><br />
                <small class="wel_text"
                  >Tailored events in inspiring surroundings.</small
                >
              </div>
            </div>
            <div>
              <button
                class="btn btn-brown"
                @click="$router.push({ path: '/meetings-and-events' })"
              >
                Explore More <span class="el-icon-d-arrow-right"></span>
              </button>
            </div>
          </div>
        </div> -->

      </div>
    </div>

    <!-- <section>
      <div class="container text-center mb-5">
        <div class="row mt-5 mb-5">
          <div class="col-md-2"></div>
          <div class="cottage_welcome col-md-8">
            <h2>COTTAGE</h2>
            <p>
              At Acaki Lodge we offer multiple types of rooms, to ensure that
              there is always an ideal one for you, fitting your needs. Whether
              you travel alone, as a group of friends or with young children.
            </p>
          </div>
          <div class="col-md-2"></div>
        </div>
        <section>
          <div class="cottages_grid">
            <div class="welcome_image">
              <img
                src="../../assets/images/room4.jpg"
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="welcome_description">
              <div>
                <span class="heading" style="font-weight: 600"
                  >Cottages</span
                ><br />
                <span class="wel_text"
                  >Ideal for Families or long staying guests, the Kookaburra
                  Cottage features three bed rooms en-suite, a cosy lounge,
                  separate dining area and a kitchen.</span
                >
              </div>
              <br />
              <button
                class="btn btn-brown"
                @click="$router.push({ path: 'accommodation-detail/1' })"
              >
                BOOK NOW <span class="el-icon-d-arrow-right"></span>
              </button>
            </div>
          </div>

          <!- <button class="btn btn-lg btn-view-more mt-5 mb-5">
            View More
          </button> ->
        </section>
      </div>
    </section> -->

    <section>
        <article class="suites-section bg-white-section">
          <div class="text-center">
            <h2 class="segoe">COTTAGES</h2>
            <p class="content-paragraph segoe">
              A serene escape amidst nature’s beauty.
            </p>
            <p>
              A peaceful retreat in the heart of nature, Acaki Lodge offers a serene escape with breathtaking landscapes, 
              luxurious cottages, and exceptional service. Immerse yourself in the tranquility and beauty of the wilderness, 
              where every detail is designed to provide ultimate relaxation and a memorable connection to the natural world.
            </p>
            <button @click="$router.push({ path: '/accommodation' })" class="btn btn-lg content-btn">VIEW COTTAGES</button>
          </div>
        </article>
    </section>

    <section>
      <article class="rooms">
        <div class="container text-center">
          <!-- <div class="row mt-5 mb-5">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <h2 style="font-weight: 600">ROOMS</h2>
              <p>
                At Acaki Lodge we offer multiple types of rooms, to ensure that
                there is always an ideal one for you, fitting your needs.
                Whether you travel alone, as a group of friends or with young
                children.
              </p>
            </div>
            <div class="col-md-2"></div>
          </div> -->
          <!-- <section>
            <div class="rooms_grid" v-for="room in rooms" :key="room.id">
              <div class="rooms_image">
                <el-carousel height="260px">
                  <el-carousel-item v-for="image in room.images" :key="image">
                    <img :src="image" class="d-block w-100" alt="..." />
                  </el-carousel-item>
                </el-carousel>
              </div>

              <div class="">
                <div>
                  <h2 style="font-size: 1.2em; font-weight: 600">
                    {{ room.title }}
                  </h2>
                  <p style="color: grey">
                    <small>
                      {{ room.description }}
                    </small>
                  </p>
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <h3 style="font-size: 1em; font-weight: 600">Services</h3>
                      <ul style="color: grey">
                        <li>
                          <small>
                            <i
                              class="fas fa-shower"
                              style="color: #d96b12; padding-right: 5px"
                            ></i>
                            Amenities in the bathroom
                          </small>
                        </li>
                        <li>
                          <small>
                            <i
                              class="fas fa-wifi"
                              style="color: #d96b12; padding-right: 5px"
                            ></i
                            >Free Wi-Fi internet
                          </small>
                        </li>
                        <li>
                          <small>
                            <i
                              class="fas fa-tv"
                              style="color: #d96b12; padding-right: 5px"
                            ></i>
                            Smart TV
                          </small>
                        </li>
                        <li>
                          <small>
                            <i
                              class="el-icon-phone-outline"
                              style="color: #d96b12; padding-right: 5px"
                            ></i>
                            Room Service
                          </small>
                        </li>
                      </ul>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                      "
                    >
                      <p></p>
                      <button
                        class="btn btn-brown btn-sm"
                        @click="accommodationDetail(room.id)"
                      >
                        BOOK NOW <span class="el-icon-d-arrow-right"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> -->
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // rooms: [
      //   {
      //     id: "3",
      //     title: "Executive Room",
      //     description:
      //       "Executive Rooms offer large king size beds and garden views from the private balconies. Ideal for business stays, this room category features an expansive workspace and a relaxation area with a comfortable couch and coffee table.",
      //     images: [
      //       require("../../assets/images/bg-2.jpg"),
      //       require("../../assets/images/bg-3.jpg"),
      //       require("../../assets/images/bg-4.jpg"),
      //     ],
      //   },
      //   {
      //     id: "2",
      //     title: "Deluxe Room",
      //     description:
      //       "Spacious Deluxe Rooms are elegantly appointed and feature views of the hotel’s water gardens from both the room and private balcony. Furnished with one king or two twin beds",
      //     images: [
      //       require("../../assets/images/image_1.jpg"),
      //       require("../../assets/images/image_3.jpg"),
      //       require("../../assets/images/bg-4.jpg"),
      //     ],
      //   },
      // ],
    };
  },

  methods: {
    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    },
  },
};
</script>

<style scoped>
.welcome-article{
  height: 100vh;
  display: flex;
  align-items: center;
}
.home_welcome_message {
}

.home_welcome_message > h2 {
  font-size: 1.5em;
  font-weight: 200;
}

.home_welcome_message .heading-two {
  font-size: 3em;
  font-weight: 400;
}

.home_welcome_message .paragraph {
  font-size: 1.5em;
  font-weight: 300;
}

  

.the-lodge-grid-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.the-lodge-grid-section>div {
  min-height: 100vh;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.the-lodge-grid-section>div:first-child,
.the-lodge-grid-section>div:nth-child(4) {
  padding: 10%;
}

.the-lodge-grid-section>.bg-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.the-lodge-grid-section h2 {
  font-size: 1.8em;
  font-weight: 400 !important;
  color: #3D290A;
}

.the-lodge-grid-section .heading-two {
  font-size: 2.5em;
  font-weight: 200;
  color: #3D290A;
}

.the-lodge-grid-section .content-paragraph {
  font-size: 1em;
  font-weight: 300;
  color: black;
}

.the-lodge-grid-section .content-btn {
  background-color: transparent;
  color: #3D290A;
  border: 2px solid #3D290A;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 300px;
}

.the-lodge-grid-section .content-btn:hover {
  background-color: #3D290A;
  color: white !important;
  transition: 0.5s;
}




.suites-section {
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)), url('../../../assets/images/acaki19.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-white-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-white-section>div {
  background-color: #fff;
  color: #3D290A;
  width: 50%;
  padding: 100px 20px;
}

.bg-white-section h2 {
  font-size: 2.5em;
  font-weight: 200 !important;
  color: #3D290A;
}

.bg-white-section .content-paragraph {
  font-size: 1.8em;
  font-weight: 400;
  color: #3D290A;
}


.bg-white-section .content-btn {
  background-color: transparent;
  color: #3D290A;
  border: 2px solid #3D290A;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 200px;
}

.bg-white-section .content-btn:hover {
  background-color: #3D290A;
  color: white !important;
  transition: 0.5s;
}


.welcome_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.welcome_grid > div {
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

.welcome_grid > div:hover {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  transition: 0.5s;
}

.welcome_flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.welcome_flex > div:nth-child(3) {
  border-radius: 0px 0px 5px 5px;
}

.welcome_grid .welcome_image {
  height: 250px;
  border-radius: 5px;
}
.welcome_grid .welcome_image img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.welcome_grid .welcome_description {
  padding: 30px 30px;
  background-color: white;
  /* border-radius: 5px; */
}

.welcome_grid .welcome_description .heading {
  font-weight: 600;
}
.welcome_grid .welcome_description .wel_text {
  color: grey;
}

.cottage_welcome h2 {
  font-weight: 600;
}

.cottages_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  background-color: #fef9f6;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.cottages_grid > div {
  background-color: #fef9f6;
  border-radius: 5px;
}

.cottages_grid .welcome_image {
  height: 400px;
  border-radius: 5px;
}
.cottages_grid .welcome_image img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.cottages_grid .welcome_description {
  text-align: left;
  padding: 100px 30px;
}

.btn-brown {
  background-color: #d96b12;
  border-radius: 0px 0px 5px 5px;
  color: white;
  width: 100%;
}

.btn-view-more {
  background-color: white;
  border: 1px solid #d96b12;
  width: 250px;
}

.rooms {
  background-color: #eeeeee;
}

.rooms_grid {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
  border-radius: 5px;
}

.rooms_grid > div {
  text-align: left;
  padding: 20px;
  border-radius: 5px;
}

.rooms_grid > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.rooms_grid .rooms_image img {
  height: 260px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.rooms_grid ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .welcome_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .the-lodge-grid-section>div {
    min-height: 50vh;
  }

  .bg-white-section {
    height: 60vh;
  }

  .bg-white-section>div {
    background-color: transparent;
    width: 60%;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .welcome_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .the-lodge-grid-section>div {
    min-height: 50vh;
  }

  .bg-white-section {
    height: 60vh;
  }

  .bg-white-section>div {
    background-color: transparent;
    width: 60%;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .home_welcome_message {
    margin-top: 80px;
  }
  /* .home_welcome_message > h2 #h21 {
    font-size: 0.6em;
  }

  .home_welcome_message > h2 #h22 {
    font-size: 0.8em;
    color: #d96b12;
  } */

  .suites-section p, .suites-section h2, .suites-section button{
    color: white !important;
  }


  .bg-white-section {
    height: 80vh;
  }

  .bg-white-section>div {
    background-color: transparent;
    width: 90%;
  }

  .bg-white-section h2 {
    font-size: 2em;
  }

  .bg-white-section .content-paragraph {
    font-size: 1.2em;
  }

  .the-lodge-grid-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .the-lodge-grid-section>div {
    min-height: 60vh;
  }

  .the-lodge-grid-section>div:nth-child(3) {
    grid-row: 4;

  }

  .the-lodge-grid-section>div:first-child,
  .the-lodge-grid-section>div:nth-child(4) {
    padding: 30px 20px;
  }

  .the-lodge-grid-section h2 {
    font-size: 1.5em;
  }

  .the-lodge-grid-section .heading-two {
    font-size: 1.8em;
  }

  .the-lodge-grid-section .content-paragraph {
    font-size: 0.8em;
  }

  .welcome_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .home_welcome_message {
    margin-top: 80px;
  }
  /* .home_welcome_message > h2 #h21 {
    font-size: 0.6em;
  }

  .home_welcome_message > h2 #h22 {
    font-size: 0.8em;
    color: #d96b12;
  } */

  .suites-section p, .suites-section h2, .suites-section button{
    color: white !important;
  }

  

  .bg-white-section {
    height: 80vh;
  }

  .bg-white-section>div {
    background-color: transparent;
    width: 90%;
  }

  .bg-white-section h2 {
    font-size: 2em;
  }

  .bg-white-section .content-paragraph {
    font-size: 1.2em;
  }

  .the-lodge-grid-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .the-lodge-grid-section>div {
    min-height: 60vh;
  }

  .the-lodge-grid-section>div:nth-child(3) {
    grid-row: 4;

  }

  .the-lodge-grid-section>div:first-child,
  .the-lodge-grid-section>div:nth-child(4) {
    padding: 30px 20px;
  }

  .the-lodge-grid-section h2 {
    font-size: 1.5em;
  }

  .the-lodge-grid-section .heading-two {
    font-size: 1.8em;
  }

  .the-lodge-grid-section .content-paragraph {
    font-size: 0.8em;
  }

  .welcome_grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .welcome_grid .welcome_image {
    height: 150px;
    border-radius: 5px;
  }
  .welcome_grid .welcome_image img {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .welcome_grid .welcome_description {
    padding: 20px 10px;
    background-color: white;
    border-radius: 5px;
  }

  .welcome_grid .welcome_description .heading {
    font-size: 0.7em;
  }
  .welcome_grid .welcome_description .wel_text {
    font-size: 0.8em;
  }

  .cottage_welcome h2 {
    font-size: 1.3em;
  }

  .cottage_welcome p {
    font-size: 0.85em;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .cottages_grid .welcome_image {
    height: 250px;
    border-radius: 5px;
  }
  .cottages_grid .welcome_image img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .cottages_grid .welcome_description {
    text-align: center;
    padding: 50px 10px;
    background-color: white;
    border-radius: 5px;
  }

  .cottages_grid .welcome_description .heading {
    font-size: 0.7em;
  }
  .cottages_grid .welcome_description .wel_text {
    font-size: 0.8em;
  }

  .rooms h2 {
    font-size: 1.2em;
  }
  .rooms p {
    font-size: 0.8em;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }

  .btn-brown {
    background-color: #d96b12;
    color: white;
    width: 100%;
    font-size: 0.7em;
  }
}
</style>