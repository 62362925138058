<template>
  <div v-loading="loading" style="padding-top: 150px;">
    <section>
      <article class="reservation-page mt-5">
        <div class="container mt-5">
          <div class="row">
            <div class="col-md-6">
              <h2>Your Reservation</h2>
              <hr/>
                {{ category }}<br/>
                {{ $route.query.plan }}
                <p style="font-weight: 600; font-size: 1.2em;">
                    {{ category.categoryName }}
                </p>
                <p>
                    Check-In Date: 
                    <span style="font-weight: 600;">
                        {{ formattedDate(category.checkInDate) }}
                    </span><br/>
                    Check-Out Date: 
                    <span style="font-weight: 600;">
                        {{ formattedDate(category.checkOutDate) }}
                    </span>
                </p> 
                <p>
                    <span>{{ nights }} Nights. </span><br/>
                    <span>
                        <i class="el-icon-user-solid"></i>
                        {{category.numberOfAdults}} Adults                           
                        <i class="el-icon-user-solid"></i>
                        {{category.numberOfChildren}} Children
                    </span>
                </p>
                <p>
                    Total:
                    <span style="font-weight: 600;">
                        $ {{ formatNumber(category.pricePerNight) }}
                    </span>
                </p>
            </div>
            <div class="col-md-6">
              <h2>Guest Information</h2>

              <el-form
                :model="reservationForm"
                :rules="rules"
                label-position="top"
                ref="reservationForm"
                label-width="120px"
                class="demo-reservationForm"
              >
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="First name" prop="firstName">
                      <el-input v-model="reservationForm.firstName"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <el-form-item label="Last Name" prop="lastName">
                      <el-input v-model="reservationForm.lastName"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Email" prop="email">
                      <el-input v-model="reservationForm.email"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <el-form-item label="Phone Number" prop="phoneNumber">
                      <el-input v-model="reservationForm.phoneNumber"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Address" prop="address">
                      <el-input v-model="reservationForm.address"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <el-form-item label="Country" prop="country">
                      <el-input v-model="reservationForm.country"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row mt-4 mb-5">
                  <div class="col-md-12">
                    <el-form-item>
                      <el-button
                        style="width: 100%; background-color: #d96b12; color: white"
                        @click="submitForm('reservationForm')"
                        >Submit Reservation <i class="el-icon-right"></i></el-button
                      >
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
export default {
  data() {
    return {
      loading: false,
      reservationForm: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        address: "",
        country: "",
        categoryName: "",
        room_code: "",
        checkInDate: "",
        checkOutDate: "",
        amount: "",
        number_of_rooms: "",
        numberOfChildren: "",
        numberOfAdults: ""
      },
      rules: {
        firstName: [
          {
            required: true,
            message: "Please input First Name",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please input Last Name",
            trigger: "blur",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: "Please input phoneNumber",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please input email",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Please input Address",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "Please input Country",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    category() {
        return JSON.parse(localStorage.getItem("category_details"));
    },
    nights() {
        const date1 = new Date(`${this.category.checkInDate}`);
        const date2 = new Date(`${this.category.checkOutDate}`);
        const absDate1 = Math.abs(date1);
        const absDate2 = Math.abs(date2);
        const ceilDate1 = Math.ceil(absDate1 / (1000 * 60 * 60 * 24));
        const ceilDate2 = Math.ceil(+absDate2 / (1000 * 60 * 60 * 24));
        const diffDays = ceilDate2 - ceilDate1;
        return diffDays;
    },

    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
    },
  },
  
  methods: {
    handleClose(done) {
      done();
    },
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          
              
          try {
            let request = await this.$http.post(
              `reservations`, {
                firstName: this.reservationForm.firstName,
                lastName: this.reservationForm.lastName,
                phoneNumber: this.reservationForm.phoneNumber,
                email: this.reservationForm.email,
                address: this.reservationForm.address,
                country: this.reservationForm.country,
                // "customerID": 1,
                roomCategoryID: this.$route.query.plan,
                checkInDate: this.category.checkInDate,
                checkOutDate: this.category.checkOutDate,
                totalAmount: this.category.pricePerNight,
                numberOfAdults: this.category.numberOfAdults,
                numberOfChildren: this.category.numberOfChildren,
                specialRequests:"My Special Requests",


              }
            );
            if (
              request.data.success &&
              request.data.message == "RESERVATION_CREATED_SUCCESSFULLY"
            ) {
              // let redirectUrl = request.data.card_processor;
              // window.location.replace(redirectUrl);
              this.$router.push({
                name: "Payment",
                query: { request: 'successful' },
              });

              return this.$notify.success({
                title: 'Success',
                message: 'Reservation created successfully'
              });

            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify.error({
                title: 'Connection failed',
                message: 'A network error occurred, please try again.'
              });
            }
            this.$notify.error({
                title: 'Loading Failed',
                message: 'Unable to place a reservation now, Please try again later'
              });
          } finally {
            this.loading = false;
          }
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Unable to place a reservation.'
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style>
.reservation-page {
  min-height: 100vh;
  display: flex;
  /* align-items: center; */
}

.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>