<template>
  <div class="acaki-dining">
    <!-- <article>
      <CarouselComponent></CarouselComponent>
    </article> -->

    <section>
      <article>
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="dining_introduction">
            <h3>Meetings and Events Services</h3>
            <!-- <p style="font-weight: 600; font-size: 1.2em">
              Fine Dining Restaurant in  Kitgum
            </p> -->
            <p style="font-weight: 300">
              Acaki Lodge Kitgum Limited runs two-wings restaurants to
              help provide Hotel Services to its’ clients. Both Local and
              International dishes are provided.
            </p>
            <!--
            <h4>The Restaurants are;</h4>
            <ol>
              <li>Acaki Restaurant </li>
              <li>Café Alpha Restaurant</li>
            </ol>
            <p>In providing hotel services, the restaurants prepare
              food items to clients as follows;</p> -->
          </div>
        </div>
      </article>
    </section>

    <section>
      <article style="border: 1px solid transparent; background-color: #f6e0bf;">
        <div class="container" style="margin: 0px auto 50px auto; text-align: left">
          <div class="dining_grid">
            <div class="dining_flex" v-for="dining in dining_features" :key="dining.id">
              <div>
                <div class="dining_grid_image">
                  <img :src=" dining.image " />
                </div>
                <div class="dining_features">
                  <div class="mt-3">
                    <p style="font-weight: 600; font-size: 1.2em">
                      {{ dining.title }}
                    </p>
                    <p style="font-weight: 300">
                      {{ dining.description }}
                    </p>
                  </div>
                </div>
              </div>
                <div>
                  <!-- <BookTableComponent/> -->
                </div>
            </div>
            


          </div>
        </div>
      </article>
    </section>

    <section>
      <article
        style="
          border: 1px solid transparent;
          background-color: #f6e0bf;
        "
      >
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="meetingArticle1">
            <h2 style="font-weight: 300; font-size: 1.2em">
              TAILORED EVENTS IN INSPIRING SURROUNDINGS
            </h2>
            <p style="font-weight: 400; font-size: 1.2em">
       
              Welcome to Acaki Lodge's events at a venue like no other. With a
              variety of locations at the Lodge available for your Events,
              Functions or Celebrations; Acaki gives you a choice of lush
              gardens hosting a number of people .
              <br>
               Accompanied by
              our signature Acaki Lodge Guest Care, your menus are customised to
              meet your guest preferences and are personally discussed with our
              Executive Chef to ensure the total satisfaction of each and every
              guest invited to your function. Our philosophy at the Acaki Lodge
              Events department is "We understand your need to impress each of
              your invited guests and we partner with you to make you proud by
              ensuring every event booked with us is memorable experience to
              everyone in attendance which will result in you pride and
              satisfaction as a Host"
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article
        style="
          border: 1px solid transparent;
          background-color: #f6e0bf;
        "
      >
       
      </article>
    </section>

    <section>
      <article
        class="pt-5 pb-5"
        style="
          border: 1px solid transparent;
          background-color: #503711;
          color: #fbf0e0;
        "
      >
        <div class="container" style="text-align: left">
          <h2 class="text-center" style="font-weight: 600; font-size: 1.5em">
            WORLD-CLASS BUSINESS SUPPORT
          </h2>
          <div class="venue_features">
            <ul>
              <li>
                <img src="../../assets/svgs/board.svg" />
              </li>
              <li>
                <img src="../../assets/svgs/box.svg" />
              </li>
              <li>
                <img src="../../assets/svgs/cup.svg" />
              </li>
              <li>
                <img src="../../assets/svgs/paperclip.svg" />
              </li>
              <li>
                <img src="../../assets/svgs/wifi.svg" />
              </li>
              <li>
                <img src="../../assets/svgs/tv.svg" />
              </li>
            </ul>
          </div>
          <div class="text_features">
            <div class="row">
              <div class="col-md-6">
                <ul>
                  <li>Full security</li>
                  <li>Wide selection of incentives options</li>
                  <li>
                    Complimentary Wi-Fi and a full range of global
                    communications
                  </li>
                  <li>Central location with ample, secure parking</li>
                  <li>Dedicated multilingual staff with attention to detail</li>
                  <li>PA system and cordless microphones available on hire</li>
                   <li>TV/video/DVD LCD projector and screen available on hire</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul>
                 
                  <li>White boards, flip charts and markers</li>
                 
                 <li>
                    Registration staff and conference organization support
                  </li>
                  <li>24-hour generator back-up</li>
                  <li>Concierge services</li>
                  
                  <li>
                    Entertainment packages for your guests’ spouses and partners available on request at a fee
                  </li>
                  <li>Floral services available at a fee.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
// import CarouselComponent from "../../components/website/meetingsAndEvents/carousel-component.vue";

export default {
  // components: { CarouselComponent },

  data() {
    return {
      tableData: [
        {
          name: "THE AUDITORIUM",
          banquet: "200",
          reception: "150",
          theatre: "400",
          classroom: "80",
          boardroom: "80",
        },
        {
          name: "THE GARDEN AUDITORIUM",
          banquet: "100",
          reception: "150",
          theatre: "400",
          classroom: "80",
          boardroom: "80",
        },
        {
          name: "THE CRANE ROOM",
          banquet: "250",
          reception: "150",
          theatre: "400",
          classroom: "80",
          boardroom: "80",
        },
      ],

      

      dining_features: [
          {
            id: "1",
            title: "CHILDREN’S PARK ",
            description: "Acaki Lodge offers Children’s play grounds with varieties of playing implements which includes the following;",
            image: require("../../assets/images/meetings/children.jpg")
          },
          {
            id: "2",
            title: "CONFERENCE FACILITIES",
            description: "Acaki Lodge has two conference halls that accommodate 100 pax, and another which accommodates 50 pax. There is also a Board room for 10 pax.",
            image: require("../../assets/images/meetings/confernce_room_1.jpg")
          },
          {
            id: "3",
            title: "GARDENS",
            description: "The gardens at Acaki Lodge offer a serene retreat with lush greenery, vibrant flowers, and winding paths. Perfect for relaxation, events, or nature walks, they provide a peaceful escape in the heart of nature.",
            image: require("../../assets/images/meetings/gardens.jpg")
          },
          {
            id: "4",
            title: "PLAYGROUNDS",
            description: "The playgrounds at Acaki Lodge include a well-maintained football field, providing a spacious area for guests to enjoy friendly matches, practice skills, or simply engage in outdoor sports and recreation.",
            image: require("../../assets/images/meetings/playground.jpg")
          }
        ]
    };
  },

  methods: {
    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.acaki-dining {
  padding-top: 200px;
}
.dining_introduction {
  width: 60%;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

  .dining_flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .dining_flex >div:nth-child(3){
    border-radius: 0px 0px 5px 5px;
  }

.dining_grid > div .dining_grid_image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.goBtn {
  color: white;
  font-weight: 600;
  transition: 0.5s;
}

.dining_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

.goBtn {
  background-color: transparent;
  border: 1.5px solid #3d290a;
  color: #3d290a;
  font-weight: 600;
  transition: 0.5s;
}

.dining_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

.venue_features {
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.venue_features ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 50px;
}

.meetingArticle1 {
  width: 60%;
}

.text_features {
  width: 80%;
  margin: 30px auto;
}

.text_features ul li {
  font-weight: 300;
  margin-bottom: 10px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .text_features {
    width: 100%;
  }

  .meetingArticle1 {
    width: 100%;
  }

  .venue_features ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .text_features {
    width: 100%;
  }

  .meetingArticle1 {
    width: 100%;
  }

  .venue_features ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
}
</style>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>